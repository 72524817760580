<script lang="ts">
import { defineComponent, PropType } from 'vue';
import type { IAPIImage } from '@hokify/common';

export default defineComponent({
	name: 'HokBgImg',
	computed: {
		backgroundImage(): { backgroundImage: string } | undefined {
			const optimizedUrl = this.imageUrl
				? this.$img(this.imageUrl, {
						format: 'webp',
						width: this.width > 0 ? this.width : undefined,
						height: this.height > 0 ? this.height : undefined
					})
				: '';

			return optimizedUrl ? { backgroundImage: `url("${optimizedUrl}")` } : undefined;
		},
		widthStyle(): { width: string } | undefined {
			return this.width ? { width: `${this.width}px` } : undefined;
		},
		heightStyle(): { height: string } | undefined {
			return this.height ? { height: `${this.height}px` } : undefined;
		},
		imageUrl(): string {
			return (
				this.source && (typeof this.source === 'string' ? this.source : this.source[this.size])
			);
		},
		stylesArray() {
			const arr: any[] = [];
			if (this.widthStyle) {
				arr.push(this.widthStyle);
			}
			if (this.heightStyle) {
				arr.push(this.heightStyle);
			}
			if (this.backgroundImage) {
				arr.push(this.backgroundImage);
			}
			return arr;
		}
	},
	props: {
		bgCover: { type: Boolean, default: true },
		source: {
			type: [String, Object] as PropType<string | IAPIImage>,
			required: true
		},
		alt: { type: String, default: '' },
		size: { type: String, default: 'medium' },
		width: { type: Number, default: 0 },
		height: { type: Number, default: 0 }
	}
});
</script>

<template>
	<div :title="alt" :class="[bgCover ? 'bg-cover' : 'bg-contain']" :style="stylesArray">
		<slot></slot>
	</div>
</template>
